import React from 'react';
import '../global.css';
import {
  motion
} from 'framer-motion';
import { useTranslation } from 'react-i18next';

const featureAnimation = {
  visible: custom => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 }
  }),
  hidden: {
    y: 200,
    opacity: 0,

  },
}

const textAnimation = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: custom => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 }
  }),
}

const Features = () => {
  const { t } = useTranslation();

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ amount: 0.2 }}
      id="features" className="relative w-full h-auto overflow-hidden text-left text-lg font-hero py-12">
      <div className="relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 flex flex-col lg:flex-row lg:items-start">
        <motion.h1 variants={textAnimation} custom={1} className="lg:w-[280px] text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-medium my-auto mb-6 lg:mb-[20%] lg:mr-10">
          {t('headline3')}
        </motion.h1>
        <div id="list_of_features" className="lg:w-2/3 grid grid-cols-1 sm:grid-cols-2 gap-8">
          <motion.div
            variants={featureAnimation}
            custom={1}
            id="feature1" className="flex flex-col items-start text-left">
            <img className="h-12 w-12 mb-4" alt={t('feature1_title')} src="/coding.svg" />
            <h2 className="text-xl font-medium">{t('feature1_title')}</h2>
            <p className="text-neutral-600 text-sm w-full sm:w-[250px] mt-2">
              {t('feature1_description')}
            </p>
          </motion.div>
          <motion.div
            variants={featureAnimation}
            custom={2} id="feature2" className="flex flex-col items-start text-left">
            <img className="h-12 w-12 mb-4" alt={t('feature2_title')} src="/design.svg" />
            <h2 className="text-xl font-medium">{t('feature2_title')}</h2>
            <p className="text-neutral-600 text-sm w-full sm:w-[250px] mt-2">
              {t('feature2_description')}
            </p>
          </motion.div>
          <motion.div
            variants={featureAnimation}
            custom={3} id="feature3" className="flex flex-col items-start text-left">
            <img className="h-12 w-12 mb-4" alt={t('feature3_title')} src="/support.svg" />
            <h2 className="text-xl font-medium">{t('feature3_title')}</h2>
            <p className="text-neutral-600 text-sm w-full sm:w-[250px] mt-2">
              {t('feature3_description')}
            </p>
          </motion.div>
          <motion.div
            variants={featureAnimation}
            custom={4} id="feature4" className="flex flex-col items-start text-left">
            <img className="h-12 w-12 mb-4" alt={t('feature4_title')} src="/seo.svg" />
            <h2 className="text-xl font-medium">{t('feature4_title')}</h2>
            <p className="text-neutral-600 text-sm w-full sm:w-[250px] mt-2">
              {t('feature4_description')}
            </p>
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default Features;
