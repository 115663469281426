import React from 'react';
import '../global.css';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const textAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: custom => ({
    opacity: 1,
    transition: { delay: custom * 0.5 }
  }),
}
const Projects = () => {
  const { t } = useTranslation();
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      id='projects' className="projects-container relative w-full h-full overflow-hidden text-right text-base text-neutral-4 font-hero">
      <motion.h1 variants={textAnimation} custom={1} className="title">  {t('latest_projects')}
      </motion.h1>
      <motion.h2 variants={textAnimation} custom={1.5} className="description">  {t('project_success')}
      </motion.h2>
      <div className="gallery grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 mt-8">
        <a href="https://horrordbdquest.md/" target="_blank">
          <img src="/main.png" alt="" className="project-image" />
        </a>
        <a href="https://icod.md/" target="_blank">
          <img src="/fourth.png" alt="" className="project-image" />
        </a>

        <a href="https://restaurant-flax-ten.vercel.app/" target="_blank">
          <img src="/sixth.png" alt="" className="project-image" />
        </a>
        <a href="https://horrordbdquest.md/" target="_blank">
          <img src="/second.png" alt="" className="project-image" />
        </a>
        <a href="https://icod.md/" target="_blank">
          <img src="/fifth.png" alt="" className="project-image" />

        </a>
        <a href="https://restaurant-flax-ten.vercel.app/" target="_blank">
          <img src="/third.png" alt="" className="project-image" />
        </a>

        <a href="https://travel-application-opal.vercel.app/" target="_blank">
          <img src="/seventh.png" alt="" className="project-image" />
        </a>

        <a href="https://redux-app-khaki-three.vercel.app/" target="_blank">
          <img src="/nineth.png" alt="" className="project-image" />
        </a>
        <a href="https://car-app-s1j7.vercel.app/" target="_blank">
          <img src="/eleventh.png" alt="" className="project-image" />
        </a>
        <a href="https://travel-application-opal.vercel.app/" target="_blank">
          <img src="/eighth.png" alt="" className="project-image" />

        </a>
        <a href="https://redux-app-khaki-three.vercel.app/cryptocurrencies" target="_blank">
          <img src="/tenth.png" alt="" className="project-image" />

        </a>


        <a href="https://car-app-s1j7.vercel.app/ " target="_blank">
          <img src="/twelvth.png" alt="" className="project-image" />

        </a>

      </div>
    </motion.div>
  );
};

export default Projects;
