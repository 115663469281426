import React from 'react';
import '../global.css';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation(); // Добавьте эту строку

  return (
    <div id="about" className="relative h-auto text-center text-base text-neutral-4 font-hero py-8">
      <div id="description2" className="relative mx-auto h-auto max-w-screen-lg p-4 lg:p-8 border border-solid border-neutral-2 rounded-lg">
        <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-medium text-text mb-4">
          {t('headline1')}
        </h1>
        <p className="text-base sm:text-lg md:text-xl leading-relaxed">
          {t('description1')}
        </p>
      </div>
    </div>
  );
};

export default About;
