import React, { useState } from 'react';
import "antd/dist/antd.min.css";
import { Button } from 'antd';
import '../global.css';
import { useTranslation } from 'react-i18next';
import { Dropdown, Menu } from 'antd'; // Для красивого dropdown меню
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';


const picAnimation = {
    hidden: {
        opacity: 0,
    },
    visible: custom => ({
        opacity: 1,
        transition: { delay: custom * 0.1 }
    }),
}

const MainScreenMobile = () => {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };
    const menu = (
        <Menu>
            <Menu.Item key="1" onClick={() => changeLanguage('ru')}>
                Русский
            </Menu.Item>
            <Menu.Item key="2" onClick={() => changeLanguage('md')}>
                Română
            </Menu.Item>
            <Menu.Item key="3" onClick={() => changeLanguage('en')}>
                English
            </Menu.Item>
        </Menu>
    );

    return (
        <motion.div
            initial="hidden"
            whileInView="visible"
            className="relative w-full h-full overflow-hidden mb-[150px] text-center text-[15px] text-text font-hero p-4">
            <div className="navbar-mobile relative w-full p-4 bg-white shadow-md">
                <div className="flex justify-between items-center">
                    <button onClick={toggleMenu} className="text-xl">
                        {menuOpen ? <CloseOutlined /> : <MenuOutlined />}
                    </button>
                </div>
                {menuOpen && (
                    <div className="mt-4 flex flex-col items-center gap-4">
                        <p><a href='#about' className='no-underline text-text'>{t('about')}</a></p>
                        <p><a href='#features' className='no-underline text-text'>{t('services')}</a></p>
                        <p><a href='#partners' className='no-underline text-text'>{t('partners')}</a></p>
                        <p><a href='#projects' className='no-underline text-text'>{t('projects')}</a></p>
                        <p><a href='#contact' className='no-underline text-text'>{t('contact')}</a></p>
                        <Dropdown overlay={menu} trigger={['click']}>
                            <Button>Language</Button>
                        </Dropdown>
                    </div>
                )}
            </div>            <div id="content" className="flex flex-col items-center gap-4 mt-4">
                <div id="description1" className="flex flex-col gap-y-4 items-center">
                    <motion.h1 variants={picAnimation} custom={1} className="text-2xl leading-tight font-medium">
                        {t('headline1')}
                    </motion.h1>
                    <motion.h2 variants={picAnimation} custom={1} className="text-sm leading-snug text-neutral-4 px-2">
                        {t('description1')}

                    </motion.h2>
                    <Button
                        className="w-2/4 rounded-3xl"
                        name="contact_us"
                        type="primary"
                    >
                        <a href='#contact' className='no-underline'>
                            {t('contact')}
                        </a>
                    </Button>
                    <motion.h3 variants={picAnimation} custom={1} className="tracking-wide leading-snug text-[15px] uppercase font-medium text-mediumslateblue">
                        <a href='#features' className='no-underline'>
                            {t('why_us')}
                        </a>
                    </motion.h3>
                </div>

                <div id="mockups" className="flex flex-col items-center gap-4">
                    <motion.img
                        variants={picAnimation} custom={2}
                        className="w-full max-w-xs object-cover"
                        alt="macbook"
                        src="/devicemacbook.svg"
                    />
                    <motion.img
                        variants={picAnimation} custom={2} className="w-1/4 max-w-xs object-cover"
                        alt="iPhone"
                        src="/deviceiphone-8-portrait@2x1.png"
                    />
                </div>
            </div>
        </motion.div>
    );
};

export default MainScreenMobile;
