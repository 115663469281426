import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
    ru: {
        translation: {
            "about": "О Нас",
            "services": "Услуги",
            "partners": "Наши партнеры",
            "projects": "Наши проекты",
            "contact": "Контакты",
            "headline1": "Идеи превращаем в цифровую реальность.",
            "description1": "Мы – команда профессионалов, специализирующихся на аутсорсинге и аутстаффинге программистов. Наши решения помогают компаниям из разных стран, включая Европу и РФ, реализовывать их цифровые проекты и достигать бизнес-целей. Доверьте нам подбор и управление вашими IT-ресурсами, и мы превзойдем ваши ожидания.",
            "contact_us": "Связаться с нами",
            "why_us": "Почему мы?",
            "headline2": "Креативность и технология в каждом проекте.",
            "description2": "Наша команда состоит из опытных разработчиков и IT-специалистов, которые страстно увлечены созданием инновационных и эффективных цифровых решений. Мы сопровождаем вас на каждом этапе, обеспечивая подбор и управление кадрами, чтобы конечный продукт полностью соответствовал вашим бизнес-целям. Наш подход основан на глубоких знаниях, внимании к деталям и стремлении к совершенству.",
            "headline3": "Полный спектр IT-услуг для вашего бизнеса",
            "feature1_title": "Аутсорсинг и аутстаффинг программистов",
            "feature1_description": "Предоставление квалифицированных IT-специалистов для реализации ваших проектов по всему миру.",
            "feature2_title": "Разработка и внедрение IT-решений",
            "feature2_description": "Создание и интеграция современных цифровых решений, адаптированных под ваши бизнес-задачи.",
            "feature3_title": "Поддержка и сопровождение IT-проектов",
            "feature3_description": "Комплексное обслуживание и поддержка IT-инфраструктуры для обеспечения стабильной работы.",
            "feature4_title": "Консультации и подбор кадров",
            "feature4_description": "Профессиональный подбор IT-кадров и консультации по оптимизации ресурсов вашего бизнеса.",
            "latest_projects": "Наши последние проекты",  // Добавленный перевод
            "project_success": "Каждый проект — это шаг к вашему успеху.",  // Добавленный перевод
            "partners_title": "Наши партнеры",  // Добавленный перевод
            "partners_description": "Мы гордимся нашими партнерами и тем, что работаем с ведущими компаниями в разных отраслях. Наши партнеры – это те, кто доверяет нам свои цифровые проекты, зная, что мы обеспечим высокое качество и надежность.",
            "contact_heading": "Свяжитесь с нами",
            "send": "Отправить",
            "contact_text": "У вас есть вопрос или вы хотите обсудить проект? Свяжитесь с нами удобным для вас способом. Мы готовы ответить на все ваши вопросы и помочь вам на каждом этапе сотрудничества."
        }
    },
    md: {
        translation: {
            "about": "Despre Noi",
            "services": "Servicii",
            "partners": "Partenerii Noștri",
            "projects": "Proiectele Noastre",
            "contact": "Contacte",
            "headline1": "Transformăm ideile în realitate digitală.",
            "description1": "Suntem o echipă de profesioniști specializați în outsourcing și outstaffing pentru programatori. Soluțiile noastre ajută companiile din diverse țări, inclusiv Europa și Rusia, să își realizeze proiectele digitale și să își atingă obiectivele de afaceri. Încredințați-ne selecția și gestionarea resurselor dvs. IT și vă vom depăși așteptările.",
            "contact_us": "Contactați-ne",
            "why_us": "De ce noi?",
            "headline2": "Creativitate și tehnologie în fiecare proiect.",
            "description2": "Echipa noastră este formată din dezvoltatori și specialiști IT cu experiență, pasionați de crearea de soluții digitale inovatoare și eficiente. Vă sprijinim la fiecare pas, asigurând selecția și gestionarea personalului, pentru a ne asigura că produsul final corespunde pe deplin obiectivelor dvs. de afaceri. Abordarea noastră se bazează pe cunoștințe profunde, atenție la detalii și dorință de excelență.",
            "headline3": "Gama completă de servicii IT pentru afacerea dvs.",
            "feature1_title": "Outsourcing și outstaffing pentru programatori",
            "feature1_description": "Furnizarea de specialiști IT calificați pentru realizarea proiectelor dvs. la nivel global.",
            "feature2_title": "Dezvoltarea și implementarea soluțiilor IT",
            "feature2_description": "Crearea și integrarea soluțiilor digitale moderne, adaptate nevoilor afacerii dvs.",
            "feature3_title": "Suport și întreținere pentru proiecte IT",
            "feature3_description": "Servicii complete de suport și întreținere a infrastructurii IT pentru asigurarea funcționării stabile.",
            "feature4_title": "Consultanță și selecție de personal",
            "feature4_description": "Selecție profesională a personalului IT și consultanță pentru optimizarea resurselor afacerii dvs.",
            "latest_projects": "Ultimele noastre proiecte",  // Добавленный перевод
            "project_success": "Fiecare proiect este un pas către succesul vostru.",  // Добавленный перевод
            "partners_title": "Partenerii Noștri",  // Добавленный перевод
            "partners_description": "Suntem mândri de partenerii noștri și de faptul că lucrăm cu companii de top din diverse industrii. Partenerii noștri sunt cei care ne încredințează proiectele lor digitale, știind că vom asigura o calitate și o fiabilitate superioară." // Добавленный перевод
            , "contact_heading": "Contactați-ne",
            "contact_text": "Aveți o întrebare sau doriți să discutați un proiect? Contactați-ne în modul care vă convine. Suntem gata să răspundem la toate întrebările dvs. și să vă ajutăm la fiecare pas al colaborării."
            , "send": "Trimite",
        }
    },
    en: {
        translation: {
            "about": "About Us",
            "services": "Services",
            "partners": "Our Partners",
            "projects": "Our Projects",
            "contact": "Contact Us",
            "headline1": "Turning ideas into digital reality.",
            "description1": "We are a team of professionals specializing in outsourcing and outstaffing for programmers. Our solutions help companies from various countries, including Europe and Russia, realize their digital projects and achieve their business goals. Trust us with the selection and management of your IT resources, and we will exceed your expectations.",
            "contact_us": "Contact Us",
            "why_us": "Why Us?",
            "headline2": "Creativity and technology in every project.",
            "description2": "Our team consists of experienced developers and IT specialists who are passionate about creating innovative and efficient digital solutions. We support you at every step, ensuring the selection and management of personnel to ensure that the final product fully meets your business goals. Our approach is based on deep knowledge, attention to detail, and a commitment to excellence.",
            "headline3": "Full range of IT services for your business",
            "feature1_title": "Outsourcing and outstaffing for programmers",
            "feature1_description": "Providing qualified IT specialists for implementing your projects worldwide.",
            "feature2_title": "Development and implementation of IT solutions",
            "feature2_description": "Creating and integrating modern digital solutions tailored to your business needs.",
            "feature3_title": "Support and maintenance of IT projects",
            "feature3_description": "Comprehensive service and support of IT infrastructure to ensure stable operation.",
            "feature4_title": "Consulting and personnel selection",
            "feature4_description": "Professional IT personnel selection and consulting to optimize your business resources.",
            "latest_projects": "Our Latest Projects",  // Добавленный перевод
            "project_success": "Every project is a step towards your success.",  // Добавленный перевод
            "partners_title": "Our Partners",  // Добавленный перевод
            "partners_description": "We are proud of our partners and the fact that we work with leading companies in various industries. Our partners are those who trust us with their digital projects, knowing that we will ensure high quality and reliability." // Добавленный перевод
            , "contact_heading": "Contact Us",
            "contact_text": "Do you have a question or want to discuss a project? Get in touch with us in a way that's convenient for you. We are ready to answer all your questions and assist you at every step of the collaboration.",
            "send": "Send",
        }
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "ru", // Язык по умолчанию
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
