import React, { useState } from 'react';
import { Button } from "antd";
import "antd/dist/antd.min.css";
import '../global.css';
import { useTranslation } from 'react-i18next';

const Contacts = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const { t } = useTranslation();
  const handleChange = (event) => {
    if (event.target.id === "email") {
      setEmail(event.target.value);
    } else if (event.target.id === "name") {
      setName(event.target.value);
    } else if (event.target.id === "description") {
      setDescription(event.target.value);
    }
  };

  const sendEmail = (event) => {
    event.preventDefault();
    // Проверяем, что имя и email не пустые
    if (!name.trim() || !email.trim() || !description.trim()) {
      alert("Пожалуйста, заполните все обязательные поля.");
      return;
    }
    window.Email.send({
      Host: "smtp.elasticemail.com",
      Username: "peachitinfo@gmail.com",
      Password: "FC3EB4B461C7890BE3A7E3CD358EE673964C",
      To: "peachitinfo@gmail.com",
      From: "peachitinfo@gmail.com",
      Subject: "Новый Клиент",
      Body: `Имя: ${name} <br/> Email: ${email} <br/> Задача: ${description} <br/>`,
    }).then(
      message => alert('Ваше сообщение отправлено'),
      error => alert("Ошибка при отправке письма: " + error)
    );
  };

  return (
    <div id="contact" className="relative flex flex-col lg:flex-row w-full h-auto lg:h-[700px] overflow-hidden text-left text-sm font-hero bg-gray-50 p-10">
      <div id="forms" className="flex flex-col w-full lg:w-1/2 gap-y-8 px-6 py-8 bg-white shadow-lg rounded-lg">
        <h1 className="text-4xl font-semibold mb-6 text-gray-800">
          {t('contact_heading')}
        </h1>
        <p className="text-base leading-6 text-gray-600 mb-6">
          {t('contact_text')}
        </p>

        <form onSubmit={sendEmail} className="flex flex-col space-y-6">
          <div className="flex flex-col lg:flex-row space-y-6 lg:space-y-0 lg:space-x-6">
            <input
              id="name"
              placeholder="Your Name"
              className="w-full lg:w-1/2 p-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
              value={name}
              onChange={handleChange}
            />
            <input
              id="email"
              placeholder="Email"
              type="email"
              className="w-full lg:w-1/2 p-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500"
              value={email}
              onChange={handleChange}
            />
          </div>
          <textarea
            id="description"
            placeholder="Your message here"
            className="w-full p-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 h-32"
            value={description}
            onChange={handleChange}
          />
          <button
            className="w-full h-12 mt-4 bg-blue-600 text-white text-lg font-medium rounded-lg hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            type="submit"
          >
            {t('send')}
          </button>
        </form>
      </div>

      <div className="flex flex-col w-full lg:w-1/2 space-y-8 px-6 py-8 mt-8 lg:mt-0">
        <div className="flex items-center space-x-4">
          <img
            className="w-7 h-7"
            src="/location.svg"
            alt="Location Icon"
          />
          <p className="text-lg leading-6 text-gray-700">
            CC Atrium, Albisoara 4, office 1412
          </p>
        </div>

        <div className="flex items-center space-x-4">
          <img
            className="w-7 h-7"
            src="/phone.svg"
            alt="Phone Icon"
          />
          <p className="text-lg leading-6 text-gray-700">
            +373 78 033 667
          </p>
        </div>

        <div className="flex items-center space-x-4">
          <img
            className="w-7 h-7"
            src="/email.svg"
            alt="Email Icon"
          />
          <p className="text-lg leading-6 text-blue-600">
            <a href="mailto:peachitinfo@gmail.com">
              peachitinfo@gmail.com
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Contacts;
