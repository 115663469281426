import React, { useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { useGLTF, OrbitControls, Text } from '@react-three/drei';

function Model(props) {
    const group = useRef();
    const { nodes, materials } = useGLTF('/model.gltf');
    const controlsRef = useRef();


    if (!nodes || !materials) {
        return null;
    }

    // Анимация автоматического вращения
    useFrame(({ clock }) => {
        const t = clock.getElapsedTime();
        if (group.current) {
            group.current.rotation.y = t * 0.3;
        }
    });

    return (
        <>
            <ambientLight intensity={0.5} />
            <directionalLight position={[10, 10, 10]} intensity={1} />
            <group ref={group} {...props} dispose={null} position={[0, -1, 0]}>
                <mesh geometry={nodes.AntennaLineBottom001.geometry} material={materials.AntennaLineBottom001} />
                <group position={[0, 1.56, 0]}>
                    <mesh geometry={nodes.Circle038.geometry} material={nodes.Circle038.material} />
                    <mesh geometry={nodes.Circle038_1.geometry} material={materials['Front.001']} />
                    <mesh geometry={nodes.Circle038_2.geometry} material={nodes.Circle038_2.material} />
                    <mesh geometry={nodes.Circle038_3.geometry} material={materials['BackGrey.001']} />
                    <mesh geometry={nodes.Circle038_4.geometry} material={materials['Rubber.001']} />
                    <mesh geometry={nodes.AntennaLineBottom001.geometry} material={nodes.AntennaLineBottom001.material} position={[0, -2.68, 0]} />
                    <mesh geometry={nodes.AntennaLineTop001.geometry} material={nodes.AntennaLineTop001.material} position={[0, 0.02, 0]} />
                    <mesh geometry={nodes.BackCameraP1001.geometry} material={materials['Black.015']} position={[0.7, 1.03, -0.09]} />
                    <mesh geometry={nodes.BackCameraBottomLens001.geometry} material={nodes.BackCameraBottomLens001.material} position={[0.7, 0.88, -0.08]} />
                    <mesh geometry={nodes.BackCameraTopLens001.geometry} material={nodes.BackCameraTopLens001.material} position={[0.7, 1.18, -0.08]} />
                    <mesh geometry={nodes.FrontCameraContainer001.geometry} material={materials['FrontCameraBlack.002']} position={[0.34, 1.32, 0.08]} />
                    <mesh geometry={nodes.FrontSpeakerBG001.geometry} material={materials['FrontSpeaker.001']} position={[0.16, 1.32, 0.08]} />
                    <mesh geometry={nodes.BackCameraTopGreyRing001.geometry} material={nodes.BackCameraTopGreyRing001.material} position={[0.7, 1.18, -0.09]} />
                    <mesh geometry={nodes.BackCameraBottomGreyRing001.geometry} material={nodes.BackCameraBottomGreyRing001.material} position={[0.7, 0.88, -0.09]} />
                    <mesh geometry={nodes.CameraBump001.geometry} material={nodes.CameraBump001.material} position={[0.7, 1.04, -0.08]} />
                    <group position={[0.97, 0.56, 0]}>
                        <mesh geometry={nodes.Circle032.geometry} material={nodes.Circle032.material} />
                        <mesh geometry={nodes.Circle032_1.geometry} material={nodes.Circle032_1.material} />
                    </group>
                    <mesh geometry={nodes.MuteSwitch001.geometry} material={nodes.MuteSwitch001.material} position={[-0.65, 0.92, 0.01]} />
                    <group position={[0.98, -0.04, 0]}>
                        <mesh geometry={nodes.Circle031.geometry} material={materials['Black.014']} />
                        <mesh geometry={nodes.Circle031_1.geometry} material={nodes.Circle031_1.material} />
                    </group>
                    <mesh geometry={nodes.VolumeButtons001.geometry} material={nodes.VolumeButtons001.material} position={[-0.66, 0.21, 0]} />
                    <mesh geometry={nodes.SCREEN.geometry} material={materials['Display.002']} />
                </group>
                <Text
                    position={[0.15, 0.4, -0.1]}
                    fontSize={0.2}
                    color="white"
                    anchorX="center"
                    anchorY="middle"
                    rotation={[0, Math.PI, 0]}
                >
                    Peach IT
                </Text>


            </group>
            <OrbitControls
                ref={controlsRef}
                enableZoom={true}
                enablePan={true}
                enableRotate={true}
                rotateSpeed={0.3}
                minDistance={4}
                maxDistance={5}
                onUpdate={(controls) => {
                    controls.enableRotate = true; // Включите вращение
                    controls.enableZoom = true; // Включите зум
                    controls.enablePan = true; // Включите панорамирование
                    // Установите ограничения вращения
                    controls.minPolarAngle = Math.PI / 2; // Устанавливает минимальный угол по оси X
                    controls.maxPolarAngle = Math.PI / 2; // Устанавливает максимальный угол по оси X
                }}
            />
        </>
    );
}

useGLTF.preload('https://vazxmixjsiawhamofees.supabase.co/storage/v1/object/public/models/iphone-x/model.gltf');

export default Model;
