import About from "../components/About";
import Features from "../components/Features";
import Portfolio from "../components/Portfolio";
import Projects from "../components/Projects";
import Contacts from "../components/Contacts";
import MainScreenMobile from "../components/MainScreenMobile";

const Mobile = () => {
    return (
        <div className="w-full relative h-full">
            <div className="absolute h-full w-full bg-whitesmoke" />
            <MainScreenMobile />
            <About />
            <Features />
            <Portfolio />
            <Projects />
            <Contacts />
        </div>
    );
};

export default Mobile;
