
import About from "../components/About";
import Features from "../components/Features";
import Portfolio from "../components/Portfolio";
import Projects from "../components/Projects";
import Contacts from "../components/Contacts";
import MainScreen from "../components/MainScreen";

const MysiteComponents = () => {
  return (
    <div className="w-full relative h-full">
      <div className="absolute h-full w-full bg-whitesmoke" />
      <MainScreen />
      <About />
      <Features />
      <Portfolio />
      <Projects />
      <Contacts />
    </div>
  );
};

export default MysiteComponents;
