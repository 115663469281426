import React from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import '../global.css';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const textAnimation = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: custom => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 }
  }),
}


const Portfolio = () => {
  const { t } = useTranslation();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  const slides = [
    { id: 1, image: '/dbd.jpg', caption: 'claustrophobia' },
    { id: 2, image: '/icod.jpg', caption: 'icod' },
  ];

  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      id='partners' className="relative w-full flex flex-col lg:flex-row h-auto overflow-hidden text-left text-lg font-hero py-12">
      <motion.div variants={textAnimation} custom={1} className="relative lg:w-1/3 w-full lg:pl-[15%] text-center lg:text-left">
        <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-medium mb-4">
          {t('partners_title')}        </h2>
        <p className="text-neutral-600 text-sm sm:text-base">
          {t('partners_description')}
        </p>
      </motion.div>
      <div className="carousel-container relative lg:w-1/4 w-[50px] mb-8 lg:mb-0">
        <Slider {...settings}>
          {slides.map((slide) => (
            <div key={slide.id} className="carousel-slide">
              <img src={slide.image} alt={slide.caption} className="carousel-image w-full  object-cover" />
            </div>
          ))}
        </Slider>
      </div>

    </motion.div>
  );
};

export default Portfolio;
