import React, { Suspense } from 'react'; // Добавьте этот импорт
import "antd/dist/antd.min.css";
import { useTranslation } from 'react-i18next';
import { Dropdown, Menu } from 'antd'; // Для красивого dropdown меню
import { Button } from 'antd';
import '../global.css';
import { motion } from 'framer-motion';
import Model from "./Model";
import { Canvas } from '@react-three/fiber';
const textAnimation = {
  hidden: {
    x: -100,
    opacity: 0,
  },
  visible: custom => ({
    x: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 }
  }),
}



const MainScreenDesktop = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const menu = (
    <Menu>
      <Menu.Item key="1" onClick={() => changeLanguage('ru')}>
        Русский
      </Menu.Item>
      <Menu.Item key="2" onClick={() => changeLanguage('md')}>
        Română
      </Menu.Item>
      <Menu.Item key="3" onClick={() => changeLanguage('en')}>
        English
      </Menu.Item>
    </Menu>
  );
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      className="relative w-full h-auto overflow-hidden text-left text-xs text-text font-hero">
      <div className="navbar">
        <div className="navbar-bg" />
        <div className="navbar-content flex justify-around py-4">
          <p><a href='#about' className='no-underline text-text'>{t('about')}</a></p>
          <p><a href='#features' className='no-underline text-text'>{t('services')}</a></p>
          <p><a href='#partners' className='no-underline text-text'>{t('partners')}</a></p>
          <p><a href='#projects' className='no-underline text-text'>{t('projects')}</a></p>
          <p><a href='#contact' className='no-underline text-text'>{t('contact')}</a></p>
          <Dropdown overlay={menu} trigger={['click']}>
            <Button>Language</Button>
          </Dropdown>
        </div>
      </div>
      <div id="content" className="flex flex-col lg:flex-row lg:justify-between lg:items-center px-5 lg:px-20 mt-[100px]">
        <div id="description1" className="flex flex-col gap-y-5 lg:w-1/2">
          <motion.h1
            custom={1}
            variants={textAnimation}
            className="text-[40px] lg:text-[50px] xl:text-[55px] 2xl:text-39xl leading-tight font-medium">
            {t('headline1')}
          </motion.h1>
          <motion.h2 custom={2} variants={textAnimation} className="relative w-[31.43%] top-[calc(50%_+_109px)] text-[15px] leading-[22px] text-neutral-4 inline-block">
            {t('description1')}
          </motion.h2>
          <Button
            className="w-40"
            name="contact_us"
            type="primary"
          >
            <a href='#contact' className='no-underline'>
              {t('contact')}
            </a>
          </Button>
          <motion.h3 variants={textAnimation} custom={3} className="tracking-widest leading-5 uppercase font-medium text-mediumslateblue">
            <a href='#features' className='no-underline'>
              {t('why_us')}
            </a>
          </motion.h3>
        </div>


        <div id="mockups" className="flex justify-center lg:justify-end items-center mt-10 lg:mt-0 lg:w-1/2">

          <Canvas>
            <ambientLight intensity={0.5} />
            <directionalLight position={[10, 10, 10]} intensity={1} />
            <Suspense fallback={null}>
              <Model />
            </Suspense>
          </Canvas>

        </div>
      </div>
    </motion.div>
  );
};

export default MainScreenDesktop;
