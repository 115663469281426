import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import MysiteComponents from "./pages/MysiteComponents";
import Mobile from "./pages/Mobile";
function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  // Состояние для хранения информации о том, является ли устройство мобильным или нет
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  // Обработчик изменения размера экрана
  const handleResize = () => {
    setIsMobile(window.innerWidth < 800);
  };

  useEffect(() => {
    // Подписываемся на событие изменения размера экрана
    window.addEventListener("resize", handleResize);

    // Отписываемся от события при размонтировании компонента
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={isMobile ? <Mobile /> : <MysiteComponents />} />
    </Routes>
  );
}
export default App;
